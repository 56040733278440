import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  TeamOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  CoffeeOutlined,
  ShopOutlined,
  CopyrightOutlined,
  ReadOutlined,
  ProfileOutlined,
  StockOutlined,
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "대시보드",
    },
    component: loadable(() => import("../pages/dashboard/List")),
  },
  {
    exact: true,
    path: "/cafe",
    sidebar: {
      icon: <ShopOutlined />,
      label: "매장 관리",
    },
    children: [
      {
        exact: true,
        path: "/account",
        sidebar: {
          label: "계정 정보",
        },
        component: loadable(() => import("../pages/cafe/Account")),
      },
      {
        exact: true,
        path: "/detail",
        sidebar: {
          label: "매장 정보 설정",
        },
        component: loadable(() => import("../pages/cafe/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon:  <ProfileOutlined />,
      label: "주문 목록",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "주문 목록",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/order/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate/schedule",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "정산 예정 목록",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 예정 목록",
        },
        component: loadable(() => import("../pages/calculate/schedule/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/calculate/schedule/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate/complete",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "정산 완료 목록",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 완료 목록",
        },
        component: loadable(() => import("../pages/calculate/complete/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/calculate/complete/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/menu",
    sidebar: {
      icon: <CoffeeOutlined />,
      label: "메뉴 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "메뉴 목록",
        },
        component: loadable(() => import("../pages/menu/List")),
      },
      {
        exact: true,
        path: "/category",
        visible: false,
        component: loadable(() => import("../pages/menu/CategoryList")),
      },
      {
        exact: true,
        path: "/option/:id",
        visible: false,
        component: loadable(() => import("../pages/menu/OptionList")),
      },
      {
        exact: true,
        path: "/option/category/:id",
        visible: false,
        component: loadable(() => import("../pages/menu/OptionCategoryList")),
      },
    ],
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <CopyrightOutlined />,
      label: "쿠폰 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "쿠폰 목록",
        },
        component: loadable(() => import("../pages/coupon/List")),
      },
      {
        exact: true,
        path: "/register",
        visible: false,
        component: loadable(() => import("../pages/coupon/Register")),
      },
      {
        exact: true,
        path: "/modify/:id",
        visible: false,
        component: loadable(() => import("../pages/coupon/Modify")),
      },
      {
        exact: true,
        path: "/history/:id",
        visible: false,
        component: loadable(() => import("../pages/coupon/HistoryList")),
      },
    ],
  },
  {
    exact: true,
    path: "/review",
    sidebar: {
      icon: <TeamOutlined />,
      label: "리뷰 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "리뷰 목록",
        },
        component: loadable(() => import("../pages/review/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/review/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/notice",
    sidebar: {
      icon: <ReadOutlined />,
      label: "공지사항",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "공지사항 목록",
        },
        component: loadable(() => import("../pages/notice/List")),
      },
      {
        exact: true,
        path: "/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/notice/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/stats",
    sidebar: {
      icon: <StockOutlined />,
      label: "통계",
    },
    children: [
      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "매출 통계",
        },
        component: loadable(() => import("../pages/stats/saleList")),
      },
      {
        exact: true,
        path: "/order",
        sidebar: {
          label: "주문 통계",
        },
        component: loadable(() => import("../pages/stats/OrderList")),
      },
      {
        exact: true,
        path: "/menu",
        sidebar: {
          label: "메뉴 통계",
        },
        component: loadable(() => import("../pages/stats/MenuList")),
      },
      {
        exact: true,
        path: "/activity",
        sidebar: {
          label: "회원 활동 통계",
        },
        component: loadable(() => import("../pages/stats/ActivityList")),
      },
    ],
  },
];
